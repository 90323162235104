<template>
  <div id="rating-summary">
    <!-- AVERAGE RATING -->
    <div
      v-if="averageRating"
      class="mb-4 d-flex justify-content-between align-items-center"
    >
      <div class="mr-4">
        <h4>{{ $t("General rating") }}</h4>
      </div>
      <div>
        <b-badge
          variant="primary p-3 d-flex justify-content-center align-items-center review-rating"
        >
          <h4 class="mb-0 text-white">
            {{ averageRating }}
            <span v-if="maxRating" class="max-rating"> / {{ maxRating }} </span>
          </h4>
        </b-badge>
      </div>
    </div>

    <!-- COMPACT RATING SUMMARY -->
    <b-row v-if="compact">
      <b-col cols="12" sm="4" class="mb-2">
        <rating-summary-compact-item
          :title="$t('Facilities')"
          :rate="rateFacilities"
        />
      </b-col>
      <b-col cols="12" sm="4" class="mb-2">
        <rating-summary-compact-item
          :title="$t('Cleaning')"
          :rate="rateCleaning"
        />
      </b-col>
      <b-col cols="12" sm="4" class="mb-2">
        <rating-summary-compact-item
          :title="$t('Arrival')"
          :rate="rateCheckin"
        />
      </b-col>
      <b-col cols="12" sm="4" class="mb-2 mb-md-0">
        <rating-summary-compact-item
          :title="$t('Location')"
          :rate="rateLocation"
        />
      </b-col>
      <b-col cols="12" sm="4" class="mb-2 mb-md-0">
        <rating-summary-compact-item
          :title="$t('Communication')"
          :rate="rateCommunication"
        />
      </b-col>
      <b-col cols="12" sm="4">
        <rating-summary-compact-item
          :title="$t('Accuracy')"
          :rate="rateAccuracy"
        />
      </b-col>
    </b-row>

    <!-- RATING SUMMARY -->
    <b-row v-else>
      <b-col cols="12" md="6" class="mb-3">
        <rating-summary-item
          :title="$t('Facilities')"
          :rate="rateFacilities"
          :max-rate="maxRating"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <rating-summary-item
          :title="$t('Cleaning')"
          :rate="rateCleaning"
          :max-rate="maxRating"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <rating-summary-item
          :title="$t('Arrival')"
          :rate="rateCheckin"
          :max-rate="maxRating"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <rating-summary-item
          :title="$t('Location')"
          :rate="rateLocation"
          :max-rate="maxRating"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3 mb-md-0">
        <rating-summary-item
          :title="$t('Communication')"
          :rate="rateCommunication"
          :max-rate="maxRating"
        />
      </b-col>
      <b-col cols="12" md="6" class="mb-3 mb-md-0">
        <rating-summary-item
          :title="$t('Accuracy')"
          :rate="rateAccuracy"
          :max-rate="maxRating"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BBadge } from "bootstrap-vue";
import RatingSummaryItem from "@/views/reviews/list/components/RatingSummaryItem.vue";
import RatingSummaryCompactItem from "@/views/reviews/list/components/RatingSummaryCompactItem.vue";

export default {
  components: {
    BRow,
    BCol,
    RatingSummaryItem,
    RatingSummaryCompactItem,
    BBadge,
  },
  props: {
    rating: {
      type: Object,
      default: null,
    },
    averageRating: {
      type: Number,
      default: null,
    },
    maxRating: {
      type: Number,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rateFacilities() {
      return this.rating?.facilities || 0;
    },
    rateCleaning() {
      return this.rating?.cleaning || 0;
    },
    rateLocation() {
      return this.rating?.location || 0;
    },
    rateCheckin() {
      return this.rating?.checkin || 0;
    },
    rateCommunication() {
      return this.rating?.communication || 0;
    },
    rateAccuracy() {
      return this.rating?.accuracy || 0;
    },
  },
};
</script>
<style lang="scss">
#rating-summary {
  .review-rating {
    width: 5rem;
    height: 5rem;
  }
  .max-rating {
    font-size: 0.9rem;
    opacity: 0.8;
  }
}
</style>
