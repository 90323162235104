<template>
  <div class="align-items-center">
    <div class="d-inline cursor-pointer text-dark" @click="translateText">
      <feather-icon icon="GlobeIcon" class="mr-1" />
      <small>
        <u class="font-weight-bold">
          {{ $t("pages.reviews.translateTo") }}
        </u>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  methods: {
    translateText() {
      const cleanText = this.text.replace(/<\/?[^>]+(>|$)/g, "");
      window.open(
        `https://translate.google.com/?sl=auto&tl=${this.locale}&text=${cleanText}&op=translate`,
        "_blank"
      );
    },
  },
};
</script>
