<template>
  <b-row class="bg-light p-2" no-gutters>
    <b-col cols="8">
      <small>{{ title }}</small>
    </b-col>
    <b-col cols="4" class="d-flex justify-content-end align-items-center">
      <small class="rate">
        {{ rate }}
      </small>
      <b-icon-star-fill />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BIconStarFill } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BIconStarFill,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    rate: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.rate {
  margin-top: 2px;
  margin-right: 5px;
}
</style>
