<template>
  <div id="review-card">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div>
        <h4 v-if="reviewAuthor" class="font-weight-bold mt-2 mb-0">
          {{ reviewAuthor }}
        </h4>

        <div
          v-if="showAccommodationName && reviewAccommodation"
          class="small font-weight-light"
        >
          {{
            $t("pages.home.reviewAccommodationTarget", {
              name: reviewAccommodation,
            })
          }}
        </div>

        <div v-if="reviewBookingLocalizator" class="small">
          <router-link
            :to="{
              name: 'foravila-booking-view',
              params: { localizator: reviewBookingLocalizator },
            }"
          >
            {{ reviewBookingLocalizator }}
          </router-link>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center">
        <b-badge
          variant="primary p-3 d-flex justify-content-center align-items-center review-rating"
        >
          <h5 class="mb-0 text-white">
            {{ reviewAverageRating }}
            <span v-if="maxRating" class="max-rating"> / {{ maxRating }} </span>
          </h5>
        </b-badge>
      </div>
    </div>

    <!-- Rating Summary -->
    <div
      v-if="!compact"
      class="py-4 border-top"
      :class="{
        'border-bottom':
          reviewComment || (showPrivateComment && reviewPrivateComment),
      }"
    >
      <rating-summary :rating="reviewRating" :max-rating="maxRating" compact />
    </div>

    <!-- Compact Comments -->
    <template v-if="compact && reviewComment">
      <!-- eslint-disable vue/no-v-html -->
      <span class="mt-4" v-html="reviewCommentToDisplay" />
      <b-link
        v-if="showViewFullCommentLink"
        class="ml-2 text-dark"
        @click="viewFullComment = true"
      >
        <u>{{ $t("See more") }}</u>
      </b-link>
      <translate-link
        v-if="showTranslationLink"
        class="mt-3"
        :text="reviewCommentToDisplay"
        :locale="currentLocale"
      />
    </template>

    <!-- Full Comments -->
    <template v-else>
      <template v-if="reviewComment">
        <h5 class="mt-4 mb-2">
          {{ $t("Public review") }}
        </h5>
        <span class="mt-2" v-html="reviewCommentToDisplay" />
        <b-link
          v-if="showViewFullCommentLink"
          class="ml-2 text-dark"
          @click="viewFullComment = true"
        >
          <u>{{ $t("See more") }}</u>
        </b-link>
        <translate-link
          v-if="showTranslationLink"
          class="mt-3"
          :text="reviewCommentToDisplay"
          :locale="currentLocale"
        />
      </template>

      <template v-if="showPrivateComment && reviewPrivateComment">
        <h5 class="mt-5 mb-2">
          {{ $t("Private review") }}
        </h5>
        <span class="mt-2" v-html="reviewPrivateCommentToDisplay" />
        <b-link
          v-if="showViewFullPrivateComment"
          class="ml-2 text-dark"
          @click="viewFullPrivateComment = true"
        >
          <u>{{ $t("See more") }}</u>
        </b-link>
        <translate-link
          v-if="showTranslationLink"
          class="mt-3"
          :text="reviewPrivateCommentToDisplay"
          :locale="currentLocale"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { BBadge, BLink } from "bootstrap-vue";
import { MAX_REVIEW_RATING } from "@appConfig";
import TranslateLink from "@/views/reviews/list/components/TranslateLink.vue";
import { shortenText, stripHtmlTags } from "@methods";
import RatingSummary from "@/views/reviews/list/components/RatingSummary.vue";

export default {
  components: {
    TranslateLink,
    BBadge,
    RatingSummary,
    BLink,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    hidePrivateComment: {
      type: Boolean,
      default: false,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
    maxRating: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      MAX_REVIEW_RATING,
      MAX_WORDS: 25,
      viewFullComment: false,
      viewFullPrivateComment: false,
    };
  },
  computed: {
    showPrivateComment() {
      return !this.hidePrivateComment;
    },
    reviewAverageRating() {
      return this.review?.averageRating || null;
    },
    reviewAuthor() {
      return this.review?.author || null;
    },
    showAccommodationName() {
      return (
        this.$store.getters["ui/showAccommodationName"] &&
        !this.hideAccommodationName
      );
    },
    reviewAccommodation() {
      return this.review?.accommodation?.name || null;
    },
    reviewBookingLocalizator() {
      return this.review?.booking?.localizator || null;
    },
    reviewComment() {
      return this.review?.comment || null;
    },
    reviewCommentToDisplay() {
      if (this.viewFullComment) return this.reviewComment;
      return shortenText(this.reviewComment, this.MAX_WORDS);
    },
    showViewFullCommentLink() {
      const comment = stripHtmlTags(this.reviewComment);
      if (!comment.length || !this.reviewCommentToDisplay?.length) return false;
      return (
        !this.viewFullComment &&
        this.reviewCommentToDisplay.length < comment.length
      );
    },
    reviewPrivateComment() {
      return this.review?.privateComment || null;
    },
    reviewPrivateCommentToDisplay() {
      if (this.viewFullPrivateComment) return this.reviewPrivateComment;
      return shortenText(this.reviewPrivateComment, this.MAX_WORDS);
    },
    showViewFullPrivateComment() {
      const comment = stripHtmlTags(this.reviewPrivateComment);
      if (!comment.length || !this.reviewPrivateCommentToDisplay?.length)
        return false;
      return (
        !this.viewFullPrivateComment &&
        this.reviewPrivateCommentToDisplay.length < comment.length
      );
    },
    reviewLanguage() {
      return this.review?.language;
    },
    currentLocale() {
      return this.$i18n.locale.substring(0, 2);
    },
    showTranslationLink() {
      return this.reviewLanguage !== this.currentLocale;
    },
    reviewRating() {
      return {
        accuracy: this.review.rateAccuracy,
        checkin: this.review.rateCheckin,
        cleaning: this.review.rateCleaning,
        communication: this.review.rateCommunication,
        facilities: this.review.rateFacilities,
        location: this.review.rateLocation,
      };
    },
  },
};
</script>
<style lang="scss">
#review-card {
  .review-rating {
    width: 4rem;
    height: 4rem;
  }
  .max-rating {
    font-size: 0.85rem;
    opacity: 0.8;
  }
  .bg-gradient-primary:hover:not(.disabled):not(:disabled),
  .btn-gradient-primary:hover:not(.disabled):not(:disabled) {
    transform: translate(0px) !important;
  }
}
</style>
