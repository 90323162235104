<template>
  <b-row class="align-items-center">
    <b-col cols="12" md="4" class="mb-2 mb-md-0 text-nowrap">
      {{ title }}
    </b-col>
    <b-col cols="12" md="8">
      <b-progress :value="rate" :max="maxRate" show-value :precision="1" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BProgress } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BProgress,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    rate: {
      type: Number,
      required: true,
    },
    maxRate: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.progress {
  height: 18px;
}
</style>
